<template>
  <div v-if="$store.state.user" class="projects">
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-data-table
        v-if="projects"
        :headers="choiceAlert"
        :items="projects"
        :options.sync="dtOptions"
        :server-items-length="projectsCount"
        :loading="loading"
        loading-text="Loading... Please wait"
        :footer-props="{'items-per-page-options': [50]}"
        :items-per-page="50"
        class="elevation-1"
        @item-expanded="loadDetails"
        light
        show-expand
        :expanded.sync="expandedClose"
    >
      <template v-slot:top>
        <v-col>
          <v-toolbar flat>
            <v-toolbar-title class="v-toolbar__title">Progetti: {{ projectsCount }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="['ADMIN', 'PURCHASE_OPERATOR', 'HEAD'].includes($store.state.user.role)"
                   color="primary" dark class="mb-2" @click="addProject">
              Aggiungi progetto
            </v-btn>
            <v-btn
                v-if="['ADMIN', 'PURCHASE_OPERATOR', 'HEAD'].includes($store.state.user.role)"
                color="if65Blue"
                dark
                class="mb-2 ml-2"
                @click="closeAll"
            >Chiudi Tutti
            </v-btn>
          </v-toolbar>
        </v-col>
        <v-row no-gutters>
          <v-col :cols="12" class="d-inline-flex">
            <v-toolbar  class="grey lighten-3 ml-2 mr-2" elevation="1" style="border-radius: 15px;">
              <v-col>
                <v-text-field
                    dense
                    v-model="customerToSearch"
                    messages="Ricerca per Insegna"
                    clearable>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    dense
                    v-model="customIdToSearch"
                    messages="Ricerca per custom ID"
                    clearable>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    dense
                    v-model="projectToSearch"
                    messages="Ricerca per Nome"
                    clearable
                >
                </v-text-field>
              </v-col>
              <v-col v-if="['ADMIN', 'HEAD'].includes($store.state.user.role)">
                <if65-autocomplete-light
                    label="``"
                    :dark="false"
                    v-model="operatorIdToSearch"
                    :hide-selected="true"
                    :api="$userService.list"
                    :extra-params="prependExtraParams"
                    :hint="`Filtra per Operatore`"
                    clearable/>
              </v-col>
              <v-col>
                <if65-autocomplete-light
                    label=""
                    :dark="false"
                    v-model="statusToSearch"
                    :api="$productService.status_choices"
                    :extra-params="prependExtraParams"
                    :hint="`Filtra per Stato`"/>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="annoDiRiferimento"
                    messages="Ricerca per Data"
                />
              </v-col>
              <v-col>
                <v-switch
                    class="mt-4"
                    dense
                    v-model="filterForAlert"
                    label="Alert"
                    false-value=""
                ></v-switch>
              </v-col>
              <v-col>
                <v-switch
                    class="mt-4"
                    dense
                    v-model="excludeSecondary"
                    label="Escludi Second."
                    false-value=""
                ></v-switch>
              </v-col>
            </v-toolbar>
          </v-col>
        </v-row>
      </template>
      <template #item.name="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ item.name }}
                    </span>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.show_expiration_alert="{ item }" v-if="['ADMIN', 'HEAD'].includes($store.state.user.role)">
        <a
            v-if="item.show_alert"
            @click="openInfoAlert(item)"
        >
          <v-badge
              v-if="item.show_alert.length > 0"
              overlap
              :content="item.show_alert.length"
              bordered
              color="red darken-1"
          >
            <v-icon color="if65Silver">mdi-bell</v-icon>
          </v-badge>
        </a>
      </template>
      <template v-slot:item.show_alert="{ item }"
                v-if="['WEB_OPERATOR', 'GRAPHIC_OPERATOR'].includes($store.state.user.role)">
        <a
            v-if="item.show_expiration_alert"
            @click="openInfoAlert(item)"
        >
          <v-badge
              v-if="item.show_alert.length > 0"
              overlap
              :content="item.show_alert.length"
              bordered
              color="red darken-1"
          >
            <v-icon color="if65Silver">mdi-bell</v-icon>
          </v-badge>
        </a>
      </template>
      <template v-slot:item.owner_full_name="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-chip color="blue lighten-4" small
                              v-if="item.owner_full_name === 'Francesca Amministratore'">{{ initialOperator(item.owner_full_name) }}</v-chip>
                      <v-chip color="red lighten-4" small v-else>{{ initialOperator(item.owner_full_name) }}</v-chip>
                    </span>
          </template>
          {{ item.owner_full_name }}
        </v-tooltip>
      </template>
      <template v-slot:item.data_promozione_dal="{item}">
        <v-chip small color='blue lighten-5'>{{ formatDate(item.data_promozione_dal) }}</v-chip>
      </template>
      <template v-slot:item.data_promozione_al="{item}">
        <v-chip small color='blue lighten-5'>{{ formatDate(item.data_promozione_al) }}</v-chip>
      </template>
      <template v-slot:item.data_sottocosto_dal="{item}">
        <v-chip small color='blue lighten-5'>{{ formatDate(item.data_sottocosto_dal) }}</v-chip>
      </template>
      <template v-slot:item.data_sottocosto_al="{item}">
        <v-chip small color='blue lighten-5'>{{ formatDate(item.data_sottocosto_al) }}</v-chip>
      </template>
      <!--            <template v-slot:item.activities_progress="{ item }">-->
      <!--              <v-chip small :color="item.activities_progress[0] === item.activities_progress[1] ? 'teal lighten-4': 'gray'">{{ item.activities_progress[0] }} di {{ item.activities_progress[1] }}</v-chip>-->
      <!--            </template>-->
      <template v-slot:item.anno_riferimento="{item}">
        <v-chip dark small :color="getColor(item.anno_riferimento)">{{ item.anno_riferimento }}</v-chip>
      </template>
      <template #item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
                small
                @click="$router.push({ name: 'detailsProject', params: { projectId: item.id } })"
            >
              mdi-list-status
            </v-icon>
          </template>
          <span>Visualizza Attività</span>
        </v-tooltip>
        <v-icon
            title="Modifica" v-if="['ADMIN', 'PURCHASE_OPERATOR'].includes($store.state.user.role)"
            small class="ml-2" @click="editProject(item)">
          mdi-pencil
        </v-icon>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on"
                class="ml-2" small
                @click="$router.push({ name: 'tasksProject', params: { projectId: item.id } })"
            >
              {{ tasksIcon }}
            </v-icon>
          </template>
          <span>Visualizza tasks</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }"
                    v-if="['ADMIN', 'PURCHASE_OPERATOR', 'HEAD'].includes($store.state.user.role)">
            <v-icon
                v-bind="attrs"
                v-on="on"
                class="ml-2" small title="Clona" @click="cloneProject(item)">
              mdi-arrange-send-backward
            </v-icon>
          </template>
          <span>Clona Progetto</span>
        </v-tooltip>
        <v-icon
            v-if="$store.state.user.role === 'ADMIN'" small class="ml-2" title="Elimina"
            @click="deleteProject(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-if="['ADMIN', 'PURCHASE_OPERATOR', 'HEAD'].includes($store.state.user.role)"
                v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <list-details :id="expanded" :project-code="item.custom_id" :status-to-search="statusToSearch"></list-details>
        </td>
      </template>
    </v-data-table>
    <!-- Modali -->
    <project-dialog
        v-if="showProjectDialog"
        v-model="showProjectDialog"
        :project-id="selectedProjectId"
        :clone="clone"/>
    <project-delete-dialog
        v-if="showProjectDeleteDialog"
        v-model="showProjectDeleteDialog"
        :project-id="projectIdToDelete"
        @confirmed="loadProjects()"/>
    <v-dialog
        v-model="openAlert"
        width="900">
      <v-card class="white">
        <v-card-title class="text-h5 grey lighten-2">
          Prodotti in scadenza: {{ detailAlert.length }}
        </v-card-title>

        <v-card-text class="white mt-4">
          <v-data-table
              :headers="headersDetailAlert"
              :items="detailAlert"
              dense
          >
            <template #item.name="{item}">
              <router-link to="/projects"><span style="text-decoration: 2px underline dotted">{{ item.name }}</span>
              </router-link>
            </template>
            <template #item.delivery_date="{item}">
              {{ formatDate(item.delivery_date) }}
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions style="background-color: white">
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              dark
              @click="openAlert = false"
          >
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProjectDeleteDialog from "./ProjectDeleteDialog.vue";
import ProjectDialog from "../../components/ProjectDialog.vue";
import ProjectService from "../../services/project.service.js";
import ListDetails from "../../components/ProjectDetail/ListDetails";
import If65AutocompleteLight from "../../components/widgets/If65AutocompleteLight";
import moment from "moment";
import 'moment/locale/it'

export default {
  components: {
    ProjectDeleteDialog,
    ProjectDialog,
    ListDetails,
    If65AutocompleteLight,
  },
  data: () => ({
    headers: [],
    headersDetailAlert: [
      {text: 'ID', value: 'id', class: 'text-dark'},
      {text: 'Nome Prodotto', value: 'name', class: 'text-dark'},
      {text: 'Scadenza', value: 'delivery_date', class: 'text-dark'},
      {text: 'Stato', value: 'status', class: 'text-dark'},
    ],
    projectToSearch: '',
    customIdToSearch: '',
    customerToSearch: '',
    operatorIdToSearch: '',
    statusToSearch: '',
    annoDiRiferimento: '',
    projects: null,
    projectId: null,
    dtOptions: {},
    projectsCount: 0,
    loading: true,
    projectIdToDelete: null,
    clone: false,
    selectedProjectId: null,
    showProjectDialog: false,
    showProjectDeleteDialog: false,
    expanded: null,
    expandedClose: [],
    snackbar: false,
    infoSnackbar: null,
    progress: false,
    // Modal
    openAlert: false,
    detailAlert: [],
    // Filter&search
    filterForAlert: null,
    excludeSecondary: true
  }),
  computed: {
    yearFromUrl() {
      return this.$route.params.anno_riferimento || null
    },
    isBackup() {
      return this.yearFromUrl !== null ? 1 : 0;
    },
    choiceAlert() {
      let user = JSON.parse(window.localStorage.getItem('user'))
      if (['ADMIN', 'PURCHASE_OPERATOR', 'HEAD'].includes(user.role)) {
        return [
          {
            value: 'show_expiration_alert',
            text: 'Alert',
            sortable: false,
          },
          {
            value: 'owner_full_name',
            text: 'Referente',
            sortable: true
          },
          {
            value: 'customer_name',
            text: 'Insegna',
            sortable: true,
          },
          {
            value: 'custom_id',
            text: 'CID',
            sortable: true,
          },
          {
            value: 'name',
            text: 'Progetto',
            sortable: false,
          },
          {
            value: 'data_promozione_dal',
            text: 'Promoz. Da',
            sortable: false,
          },
          {
            value: 'data_promozione_al',
            text: 'Promoz. A',
            sortable: false
          },
          {
            value: 'data_sottocosto_dal',
            text: 'Sottoc. Da',
            sortable: false
          },
          {
            value: 'data_sottocosto_al',
            text: 'Sottoc. A',
            sortable: false
          },
          {
            value: 'anno_riferimento',
            text: 'Anno di Rif.',
            sortable: true
          },
          {
            value: 'actions',
            text: 'Azioni',
            sortable: false,
            align: 'end'
          },
          {
            text: "",
            sortable: false,
            width: "1px",
            value: "data-table-expand"
          },
        ]
      } else {
        {
          return [
            {
              value: 'show_alert',
              text: 'Alert',
              sortable: false,
            },
            {
              value: 'owner_full_name',
              text: 'Referente',
              sortable: true
            },
            {
              value: 'customer_name',
              text: 'Insegna',
              sortable: true
            },
            {
              value: 'custom_id',
              text: 'CID',
              sortable: true
            },
            {
              value: 'name',
              text: 'Progetto',
              sortable: false
            },
            {
              value: 'data_promozione_dal',
              text: 'Promoz. Da',
              sortable: false,
            },
            {
              value: 'data_promozione_al',
              text: 'Promoz. A',
              sortable: false
            },
            {
              value: 'data_sottocosto_dal',
              text: 'Sottoc. Da',
              sortable: false
            },
            {
              value: 'data_sottocosto_al',
              text: 'Sottoc. A',
              sortable: false
            },
            {
              value: 'anno_riferimento',
              text: 'Anno di Rif.',
              sortable: true
            },
            {
              value: 'actions',
              text: 'Azioni',
              sortable: false,
              align: 'end'
            },
            {
              text: "",
              sortable: false,
              width: "1px",
              value: "data-table-expand"
            },
          ]
        }
      }
    },
    prependExtraParams() {
      return this.projectId ? {project: this.projectId, choices: ''} : {choices: ''}
    },
    tasksIcon() {
      return this.$icons.task
    },
    ordering() {
      const {sortBy, sortDesc, page, pagePerItem} = this.dtOptions
      console.log(page, pagePerItem)
      if (sortBy && sortDesc && sortBy.length === 1 && sortDesc.length === 1) {
        return {ordering: `${sortDesc[0] ? '-' : ''}${sortBy[0]}`}
      }
      return {}
    },
    showAlert() {
      return this.projects.filter(items => {
        return items.show_expiration_alert === true
      })
    }
  },
  watch: {
    filterForAlert(val) {
      if (val) {
        this.dtOptions.page = 1
        this.loading = true
        this.loadProjects()
      } else {
        this.filterForAlert = null
        this.loading = true
        this.loadProjects()
      }
    },
    projectToSearch() {
      this.loading = true
      this.loadProjects()
    },
    dtOptions: {
      handler() {
        this.loading = true
        this.loadProjects()
      },
      deep: true,
    },
    customerToSearch() {
      this.loadProjects()
    },
    customIdToSearch() {
      if (this.customIdToSearch.length >= 2) {
        this.loading = true
        this.dtOptions.page = 1
        this.loadProjects()
      }
      if (this.customIdToSearch.length < 1) {
        this.loading = true
        this.dtOptions.page = 1
        this.loadProjects()
      }
    },
    operatorIdToSearch() {
      this.loading = true
      this.loadProjects()
    },
    statusToSearch() {
      this.loading = true
      this.loadProjects()
    },
    annoDiRiferimento() {
      if (this.annoDiRiferimento.length >= 4 || this.annoDiRiferimento.length === 0) {
        this.loading = true
        this.loadProjects()
      }
    },
    yearFromUrl() {
      this.loading = true
      this.loadProjects()
    },
    showProjectDialog(newVal, oldVal) {
      if (!newVal) {
        this.clone = false
      }
      if (oldVal && !newVal) {
        this.loadProjects()
      }
    },
    excludeSecondary() {
      this.loading = true
      this.loadProjects()
    }
  },
  mounted() {
    this.loadProjects()
  },
  methods: {
    getColor(anno) {
      if (anno === 2000) return 'red darken-1'
      else if (anno === 2021) return 'blue darken-1'
      else if (anno === 2022) return 'green darken-1'
      else if (anno === 2023) return 'red darken-1'
    },
    initialOperator(full_name) {
      let names = full_name.split(' '),
          initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    },
    filterDetail(detailAlert) {
      return detailAlert.filter(items =>
          items.expired === true &&
          items.status !== 'LAVOROCHIUSO' &&
          items.status !== 'INVALFORNITORE'
      )
    },
    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },
    closeAll() {
      this.expandedClose = []
    },
    loadDetails(item) {
      this.expanded = item.item.id
    },
    formatDate(isoDate) {
      return isoDate ? moment(isoDate).format('DD/MMM/YYYY') : 'no data'
      //moment.locale('it')
      //return isoDate ? format(parseISO(isoDate).toLocaleDateString('it'), 'dd/MMM/yyyy') : 'no data'

    },
    loadProjects() {
      this.progress = true
      const params = {
        page: this.dtOptions.page || 1,
        ...this.ordering,
        custom_id__icontains: this.customIdToSearch,
        customer: this.customerToSearch,
        anno_riferimento: this.yearFromUrl ? this.yearFromUrl : this.annoDiRiferimento,
        operator: this.operatorIdToSearch,
        product_status: this.statusToSearch,
        name__icontains: this.projectToSearch,
        show_alert: this.filterForAlert,
        is_backup: this.isBackup,
        exclude_secondary: this.excludeSecondary
      }
      ProjectService.list(params).then(
          response => {
            this.projects = response.data.results;
            this.projectsCount = response.data.count
            this.loading = false
            this.progress = false
            this.closeAll()
          }
      )
    },
    addProject() {
      this.selectedProjectId = null
      this.showProjectDialog = true
    },
    cloneProject(item) {
      this.$projectService.clone(item.id).then(
          resp => {
            this.showProjectDialog = true
            this.$dtoast.pop({preset: "success", content: 'Progetto clonato'})
            this.selectedProjectId = resp.data.id
            this.clone = true
          }
      )
    },
    editProject(item) {
      this.selectedProjectId = item.id
      this.showProjectDialog = true
    },
    deleteProject(item) {
      this.projectIdToDelete = item.id
      this.showProjectDeleteDialog = true
    },
    openInfoAlert(item) {
      this.detailAlert = Object.values(item.show_alert)
      this.openAlert = true
    },
    ensureUserInStore() {
      if (!this.$store.state.user) {
        return this.$store.dispatch('setCurrentUser');
      } else {
        return Promise.resolve();
      }
    }
  },
}
</script>

<style>

.project.COMPLETE {
  border-left: 4px solid #3cd1c2;
}

.project.WIP {
  border-left: 4px solid orange;
}

.project.waiting {
  border-left: 4px solid tomato;
}

.v-chip.ongoing {
  background: #3cd1c2;
}

.v-chip.complete {
  color: orange;
}

.v-chip.waiting {
  color: tomato;
}

.my_class td {
  font-size: 12px !important;
  height: 100px !important;
}
</style>

