<template>
    <v-card elevation="2" class="if65Blue white--text">
        <v-card-title><span style="color: #030367">Progetto:</span> {{ project.name }}</v-card-title>
        <v-card-text class="white--text">
            <v-row>
                <v-col cols="4">
                    <span style="color: #030367">Ore progetto:</span> <span class="float-right" style="color: #030367">{{ project.total_hours }} ore</span>
                    <v-divider class="grey"></v-divider>
                  <span style="color: #030367">Insegna:</span> <span class="float-right" style="color: #030367">{{ project.customer_name }}</span>
                    <v-divider class="grey"></v-divider>
                    <span style="color: #030367">Stato:</span> <span class="float-right" style="color: #030367">{{ project.status_display }}</span>
                    <v-divider class="grey"></v-divider>
                </v-col>
                <v-col cols="4">
                  <span style="color: #030367">Inizio Promozione:</span> <span class="float-right" style="color: #030367">{{ formatDate(detail_project.data_promozione_dal) }}</span>
                    <v-divider class="grey"></v-divider>
                  <span style="color: #030367">Fine Promozione:</span> <span class="float-right" style="color: #030367">{{ formatDate(detail_project.data_promozione_al) }}</span>
                    <v-divider class="grey"></v-divider>
                  <span style="color: #030367">Inizio Sottocosto:</span> <span class="float-right" style="color: #030367">{{ formatDate(detail_project.data_sottocosto_dal) }}</span>
                    <v-divider class="grey"></v-divider>
                  <span style="color: #030367">Fine Sottocosto:</span> <span class="float-right" style="color: #030367">{{ formatDate(detail_project.data_sottocosto_al) }}</span>
                </v-col>
                <v-col cols="4">
                    <span style="color: #030367">Referente:</span> <span class="float-right" style="color: #030367">{{ project.owner_fullname }}</span>
                    <v-divider class="grey"></v-divider>
                    <span style="color: #030367">Codice:</span> <span class="float-right" style="color: #030367">{{ project.custom_id }}</span>
                    <v-divider class="grey"></v-divider>
                    <span style="color: #030367">Data creazione:</span> <span class="float-right" style="color: #030367">{{ formatDate(project.created_at) }}</span>
                    <v-divider class="grey"></v-divider>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import {format, parseISO} from "date-fns";
import projectService from "../../services/project.service";

export default {
    props: {
        project: {
            type: Object,
            required: true
        }
    },
    data() {
      return {
        project_id: this.project.id,
        detail_project: []
      }
    },
    mounted() {
      this.initProject()
    },
  methods: {
        initProject() {
          projectService.get(this.project_id)
            .then(resp => {
              this.detail_project = resp.data
            })
        },
        formatDate(isoDate) {
            return isoDate ? format(parseISO(isoDate), 'dd/MM/yyyy') : '-';
        }
    }
}
</script>
